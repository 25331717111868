import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import paymentFunction from "../../actions/paymentAction";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Loader from 'react-js-loader';
import moment from 'moment';

const PaymentHistory = () => {
  const [newPayment, setNewPayment] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { payment } = useSelector((state) => state.payment);

  useEffect(() => {
    dispatch(paymentFunction());
  }, [dispatch]);
  useEffect(() => {
    if (payment && payment.datasss && payment.datasss.data) {
      setNewPayment(payment.datasss.data);
      setLoading(false)
    }
  }, [payment]);

  return (
    newPayment && newPayment.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
           {loading ? (
          <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
      </div>
            ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="title">Payment History</h4>
                  <br />
                  <div className="card">
                    <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables content table-responsive table-full-width">
                      {newPayment.length > 0 ? ( 
                        <table id="datatables" className="table table-striped table-no-bordered table-hover" cellSpacing="0" width="100%">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Plan</th>
                              <th>Price</th>
                              <th style={{width: "250px"}}>Payment Date</th>
                              {/* <th>Payment Date</th> */}
                              {/* <th style={{width: "250px"}} className="disabled-sorting">Profile</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {newPayment.length > 0 && (
                              <>
                                {newPayment.map((user,i) => (
                                  <>
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{user.user[0].first_name +' '+ user.user[0].last_name}</td>
                                      <td>{user.user[0].email}</td>
                                      <td>{(user && user?.price_details) ? user?.price_details?.recurring?.interval : "NA"}</td>
                                      <td>${(user && user?.price_details) ? (user?.price_details?.unit_amount/100): "NA"}</td>
                                      <td>{user?.created_at ? moment(user.created_at).format('YYYY-MM-DD') : '-'}</td>
                                      {/* <td>{user?.created_at ? moment(user.created_at).format('DD-MM-YYYY') : '-'}</td> */}
                                    </tr>
                                  </>
                                ))}
                              </>
                            )}
                          </tbody>
                        </table>
                        ):(
                        <p>No data found.</p>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </>
    )
  );
};

export default PaymentHistory;
